<!-- 财务日志 -->
<template>
    <!-- 内容 -->
    <div class="financebox">
        <div class="ordermanageboxup">
            <ul class="d-flex flex-wrap">
                <li>
                    <div class="orderfirst reportfirst clearfloat">
                        <div class="orderfirstleft">
                            <template>
                                <el-select v-model="valuefirst" placeholder="全部">
                                    <el-option value="全部"></el-option>
                                    <el-option value="充值单号">充值单号</el-option>
                                    <el-option value="用户信息">用户信息</el-option>
                                </el-select>
                            </template>
                        </div>
                        <div class="orderfirstinput reportinp">
                            <i class="el-icon-search"></i>
                            <input type="text" placeholder="昵称、姓名、手机号、充值单号">
                        </div>
                    </div>
                </li>
                <li class="clearfloat">
                    <label class="orderlabel">操作人</label>
                    <div class="orderselect">
                        <template>
                            <el-select v-model="vipvalue" filterable clearable placeholder="请选择">
                                <el-option value="全部">全部</el-option>
                                <el-option value="管理员一">管理员一</el-option>
                                <el-option value="管理员二">管理员二</el-option>
                                <el-option value="管理员三">管理员三</el-option>
                            </el-select>
                        </template>
                    </div>
                </li>
                <li class="clearfloat">
                    <button class="ordersearch">搜索</button>
                    <button>导出报表</button>
                    <button>重置条件</button>
                </li>
            </ul>
        </div>
        <div class="financebut">
            <div class="batchtable">
                <div class="batchtablecon">
                    <table>
                        <thead>
                            <tr>
                                <th class="tableleft">用户信息</th>
                                <th>提现金额/时间</th>
                                <th>到账金额/时间</th>
                                <th>事件</th>
                                <th>操作时间</th>
                                <th>状态</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <colgroup>
                            <col style="width:18%">
                            <col style="width:13%">
                            <col style="width:12%">
                            <col style="width:12%">
                            <col style="width:17%">
                            <col style="width:14%">
                            <col style="width:14%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <td class="tableleft">
                                    <div class="memberuser clearfloat">
                                        <div class="memberuserleft">
                                            <img src="~@/assets/images/cs1.png" alt="">
                                        </div>
                                        <div class="memberuseright">
                                            <p>钟~Gavinจุ๊บ</p>
                                            <p>ID: 176</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p>积分800.00</p>
                                    <p>2023.01.01 12:30:00</p>
                                </td>
                                <td>
                                    <p>积分800.00</p>
                                    <p>2023.02.01 12:30:00</p>
                                </td>
                                <td>
                                    <p>操作员: 管理员一</p>
                                    <p>同意用户体现</p>
                                </td>
                                <td>2023.02.12 00:01:13</td>
                                <td>
                                    <div class="financezt">
                                        <span class="finsuccess"><i class="el-icon-success"></i> 成功</span>
                                        <span class="finwzf" style="display: none;"><i class="el-icon-warning"></i>
                                            未支付</span>
                                        <span class="finytk" style="display: none;"><i class="el-icon-error"></i> 已退款</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="setoperate">
                                        <a href="javascript:void(0);" @click="operatortype = true">操作人信息</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="tableleft">
                                    <div class="memberuser clearfloat">
                                        <div class="memberuserleft">
                                            <img src="~@/assets/images/cs1.png" alt="">
                                        </div>
                                        <div class="memberuseright">
                                            <p>钟~Gavinจุ๊บ</p>
                                            <p>ID: 176</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p>积分800.00</p>
                                    <p>2023.01.01 12:30:00</p>
                                </td>
                                <td>
                                    <p>积分800.00</p>
                                    <p>2023.02.01 12:30:00</p>
                                </td>
                                <td>
                                    <p>操作员: 管理员一</p>
                                    <p>同意用户体现</p>
                                </td>
                                <td>2023.02.12 00:01:13</td>
                                <td>
                                    <div class="financezt">
                                        <span class="finsuccess"><i class="el-icon-success"></i> 成功</span>
                                        <span class="finwzf" style="display: none;"><i class="el-icon-warning"></i>
                                            未支付</span>
                                        <span class="finytk" style="display: none;"><i class="el-icon-error"></i> 已退款</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="setoperate">
                                        <a href="javascript:void(0);" @click="operatortype = true">操作人信息</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="tableleft">
                                    <div class="memberuser clearfloat">
                                        <div class="memberuserleft">
                                            <img src="~@/assets/images/cs1.png" alt="">
                                        </div>
                                        <div class="memberuseright">
                                            <p>钟~Gavinจุ๊บ</p>
                                            <p>ID: 176</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p>积分800.00</p>
                                    <p>2023.01.01 12:30:00</p>
                                </td>
                                <td>
                                    <p>积分800.00</p>
                                    <p>2023.02.01 12:30:00</p>
                                </td>
                                <td>
                                    <p>操作员: 管理员一</p>
                                    <p>同意用户体现</p>
                                </td>
                                <td>2023.02.12 00:01:13</td>
                                <td>
                                    <div class="financezt">
                                        <span class="finsuccess"><i class="el-icon-success"></i> 成功</span>
                                        <span class="finwzf" style="display: none;"><i class="el-icon-warning"></i>
                                            未支付</span>
                                        <span class="finytk" style="display: none;"><i class="el-icon-error"></i> 已退款</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="setoperate">
                                        <a href="javascript:void(0);" @click="operatortype = true">操作人信息</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tablepage clearfloat">
                    <div class="orderlastbutright">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage1" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                            layout="total, sizes, prev, pager, next, jumper" :total="400">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 操作人信息弹层 -->
        <el-dialog title="操作人信息" class="operatortc" :visible.sync="operatortype">
            <div class="operatorbox">
                <p>管理员： <span>webmaster</span></p>
                <p>后台操作账号：<span>木木夕จุ๊บ</span></p>
                <p>余额充值：<span>999999 元</span></p>
                <p>备注：<span>备注说明备注说明备注说明备注说明备注说明备注说明</span></p>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="operatortype = false">取 消</el-button>
                <el-button @click="operatortype = false" class="addressqr">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currentPage1: 1,
            valuefirst: '',//充值记录
            vipvalue: '',//操作人
            operatortype: false,//控制操作人信息弹层是否显示
        }
    },
    methods: {
        handleSizeChange(val) {
            //console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            //console.log(`当前页: ${val}`);
        }
    }
}
</script>

<style scope>
@import url("css/finance.css");

</style>